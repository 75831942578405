import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { AccessibilityPDF } from '~/gatsby-theme-docz/components/AccessibilityPDF';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader"></PageHeader>
    <p>{`Universelt utformede løsninger sikrer at flest mulig forstår og kan bruke dem. Det er en viktig designbeslutning å inkludere alle, ikke bare fordi vi må på grunn av lovkrav, men fordi det er viktig å gjøre med tanke på inkludering og likestilling. Vi har høye ambisjoner om å være en foregangsbedrift på dette området. Vi ønsker at det skal være en del av vår kultur, og dette blir derfor høyt prioritert hos Entur.`}</p>
    <p>{`Enturs samfunnsansvar er at kollektive reiser skal være førstevalget, og det skal være tilgjengelig for alle. Enturs digitale løsninger må innfri krav om universell utforming, og den informasjonen som vi samler inn og viser ut i våre egne flater - og formidler videre til andre aktører – skal også være tilpasset behovene til et bredt spekter av reisende.`}</p>
    <h2 {...{
      "id": "bakgrunn-og-regelverk"
    }}>{`Bakgrunn og regelverk`}</h2>
    <p>{`Universell utforming sikrer like muligheter for alle og er en forutsetning for å unngå digitale skiller i befolkningen. Universelt utformede løsninger gir gevinster for både brukere, virksomheter og samfunnet, som økt selvbetjening og flere brukere.`}</p>
    <p>{`I tillegg til at universelt utformede løsninger er en fordel og en forventning, er det også et krav. EUs webdirektiv (WAD) er gjennomført i forskrift om universell utforming av IKT-løsninger i norsk rett. Kravene innebærer blant annet at offentlige virksomheter skal utforme nettløsningene sine i samsvar med 47 av 78 suksesskriterier etter WCAG 2.1 og publisere tilgjengelighetserklæringer for løsningene. Det er forventet at det kommer flere og utvidede krav til universell utforming i årene fremover.`}</p>
    <h2 {...{
      "id": "ansvar-og-ressurspersoner-i-entur"
    }}>{`Ansvar og ressurspersoner i Entur`}</h2>
    <p>{`UU er en integrert del av arbeidsprosessene til våre utviklingsteam. For å sikre dette tilrettelegger vi for kontinuerlig kompetansebygging og erfaringsutveksling om temaet. I tillegg strekker vi oss etter at all ny funksjonalitet er designet og utviklet etter prinsipper for universell utforming.`}</p>
    <p>{`Vi gjør også jevnlig brukertester med ulike brukergrupper for å sikre at vi hensyntar ulike behov.
Hos Entur er det enkelte team ansvarlig for at de digitale produktene de lager oppfyller krav til universell utforming. Vi tar gjerne imot tilbakemeldinger om våre nettløsninger på `}<a parentName="p" {...{
        "href": "mailto:tilbakemelding@entur.org"
      }}>{`tilbakemelding@entur.org`}</a></p>
    <h2 {...{
      "id": "uu-i-korte-trekk"
    }}>{`UU i korte trekk`}</h2>
    <p>{`På et overordnet nivå så handler Universell Utforming om “design for alle”. Dette betyr at alle sammen, i ulike situasjoner og med ulike forutsetninger skal kunne ta i bruk et produkt.
Det innebærer at tjenester skal være intuitive og fleksible, tolerere brukerfeil, lav fysisk anstrengelse, og informasjonen skal være lett tilgjengelig og forståelig.`}</p>
    <p>{`Viktige fokusområder for å dekke nødvendige krav i digitale løsninger er:`}</p>
    <ul>
      <li parentName="ul">{`Kontraster`}</li>
      <li parentName="ul">{`Fontstørrelser og zoom`}</li>
      <li parentName="ul">{`Kognitiv belastning`}</li>
      <li parentName="ul">{`Skjermleser`}</li>
    </ul>
    <p>{`Har man et fokus på disse fra start, så vil kunne unngå vanlige fallgruver, og forbedre den helhetlige brukeropplevelsen for alle brukere. For å oppnå god UU på tvers av alle produktene til Entur, er det viktige at alle sitter på et likt grunnlag om hva som er viktig i designen og utviklingen av et produkt.`}</p>
    <h2 {...{
      "id": "for-designere"
    }}>{`For designere`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Design for alle`}</strong>{`: Vi ønsker å tilby en god brukeropplevelse for alle, også de som har en temporær eller permanent funksjonsnedsettelse.`}</li>
      <li parentName="ol"><strong parentName="li">{`Språk`}</strong>{`: Språket skal være enkelt å forstå, men presist nok til å være korrekt. Vi bør unngå internt språk og forkortelser. Mengde og type tekst skal alltid tilpasses tiltenkt målgruppe og situasjon.`}</li>
      <li parentName="ol"><strong parentName="li">{`Kontrast`}</strong>{`: For å sikre god lesbarhet i våre applikasjoner er det viktig å opprettholde kontrastkrav for farger på nett.`}</li>
      <li parentName="ol"><strong parentName="li">{`Fontstørrelser`}</strong>{`: Sikre at brukere kan overstyre tekststørrelser for å gjøre teksten lettere å lese.`}</li>
      <li parentName="ol"><strong parentName="li">{`Visuell feedback`}</strong>{`: Relevant visuell feedback til brukeren, eksempelvis gode valideringstekster for skjemaer og input.`}</li>
      <li parentName="ol"><strong parentName="li">{`Klikkflate`}</strong>{`: God trykkflate på interaktive elementer.`}</li>
    </ol>
    <h3 {...{
      "id": "for-utviklere"
    }}>{`For utviklere`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`God semantisk HTML`}</strong>{`: Eksempelvis `}<inlineCode parentName="li">{`section`}</inlineCode>{`, `}<inlineCode parentName="li">{`nav`}</inlineCode>{` og `}<inlineCode parentName="li">{`main`}</inlineCode>{`, for henoldsvis seksjoner, navigering og hovedinnholdet i applikasjonen sin. `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML"
        }}>{`Les mer om semantisk html her`}</a>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Tastaturnavigasjon`}</strong>{`: Legge til rette for god tastaturnavigasjon med fokuserbarhet på relevante objekter.`}</li>
      <li parentName="ol"><strong parentName="li">{`Skjermleser`}</strong>{`: Legge til rette for skjermleser. Alt-tekst på bilder, aria-labels. Teste med skjermleser for å sikre at alt i grensesnittet er tilgjengelig, og ligger i riktig rekkefølge.`}</li>
      <li parentName="ol"><strong parentName="li">{`Responsivitet`}</strong>{`: Legge til rette for at produktet skal være responsive, slik at alle elementene er tilpasset til flaten man benytter.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      